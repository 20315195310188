// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atc-js": () => import("./../../../src/pages/atc.js" /* webpackChunkName: "component---src-pages-atc-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-r-js": () => import("./../../../src/pages/r.js" /* webpackChunkName: "component---src-pages-r-js" */),
  "component---src-pages-rec-js": () => import("./../../../src/pages/rec.js" /* webpackChunkName: "component---src-pages-rec-js" */),
  "component---src-pages-tec-js": () => import("./../../../src/pages/tec.js" /* webpackChunkName: "component---src-pages-tec-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-ventas-js": () => import("./../../../src/pages/ventas.js" /* webpackChunkName: "component---src-pages-ventas-js" */)
}

